.nav_bar {
    background: #fff;
    padding: 20px 0px;
    width: 100%;
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.3);
    height: 100px;
    /* position: fixed;
    top: 0;
    z-index: 1; */
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#navbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

#navbar li {
    list-style: none;
    padding: 0 10px;
    position: relative;
}



#navbar li a {
    display: inline;
    text-decoration: none;
    font-size: 1rem;
    /* font-weight: 600; */
    font-family: Oxygen, sans-serif;
    color: #000;
    transition: 0.3s ease-in-out;
}

#navbar li a:hover {
    background: rgb(241, 178, 59);
    padding: 10px;
    border-radius: 4px;
}


img {
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
}

#mobile {
    display: none;
}

#mobile i {
    color: #000000;
}

@media screen and (max-width:769px) {
    #navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 100px;
        left: -300px;
        width: 300px;
        height: 100vh;
        background: #fff;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
    }

    #navbar.active {
        left: 0px;
        z-index: 1;
    }

    #navbar li {
        margin-bottom: 25px;
        padding: 0 10px;
    }

    #mobile {
        display: block;
    }

    #mobile i {
        font-size: 24px;
        cursor: pointer;
    }

    #navbar li a:hover {
        background: rgb(241, 178, 59);
        padding: 10px 70px;
        border-radius: 4px;
    }
}