.contact-body {
  padding: 50px 0;
}

form .d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  gap: 5%;
}

.contact-body h3 {
  margin-bottom: 34px;
  color: #666666;
  font-size: 24px;
  font-weight: 500;
  font-family: "Oxygen", sans-serif;
}

.input-field {
  margin: 40px 0;
  width: 47%;
}

.input-field label,
.text-area textarea {
  padding: 0;
  border: none;
  outline: none;
  margin: 11px 0;
  display: block;
}

.input-field input,
.text-area textarea,
.input-field select {
  line-height: 23px;
  height: 44px;
  border: none;
  outline: none;
  padding: 11px 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  color: inherit;
}

.text-area textarea {
  min-height: 176px;
  resize: vertical;
}

.submit_btn {
  cursor: pointer;
  line-height: 23px;
  height: 44px;
  padding: 11px 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  color: #ffff;
  font-size: 18px;
  background: #ffb100;
}