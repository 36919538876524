* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  font-size: 1em;
  font-family: "Lora", serif;
  font-weight: 400;
  line-height: 1.45;
  color: #666666;
  overflow-x: hidden;
}

.container {
  max-width: 100%;
  width: 90%;
  margin: auto;
}

.wrapper {
  padding: 50px 0;
}

.page_title {
  background-color: #f9b418;
  padding: 35px 5px;
  width: 99%;
}

.page_title h1 {
  margin-left: 5.8%;
  color: #ffff;
  font-weight: 500;
  font-size: 24px;
}

.slide_Wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  gap: 8%;
}

.slide_bg {
  padding: 34px 23px;
  background-color: #faed27;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin: 30px auto;
  width: 25%;
  align-items: center;
}

.slide_bg img {
  max-width: 100%;
  height: auto;
  width: 50%;
  margin: auto;
  vertical-align: bottom;
}

.text_Wrap {
  width: 75%;
  margin: auto;
  text-align: justify;
}

.offering .wrapper:last-child .slide_Wrap {
  flex-direction: row-reverse;
}

.reference {
  display: flex;
  justify-content: space-between;
  gap: 5%;
  margin: auto;
}

.reference .d-flex {
  position: relative;
  margin: 30px auto;
  text-align: center;
}

.reference .d-flex p {
  text-align: justify;
}

.reference .wrapper {
  width: 50%;
}

.vc_sep_line {
  height: 1px;
  border-top: 1px solid #ebebeb;
  border-color: #ebebeb;
  display: block;
}

.whats_new .d-flex {
  display: flex;
  gap: 5%;
  margin: 30px 0;
}

.whats_new_slide_bg {
  padding: 56px 0px;
  background-color: #faed27;
  text-align: center;
  width: 26%;
  height: 100%;
  border-radius: 10px;
}

.whats_new_slide_bg img {
  width: 70px;
}

.whats_new_slide_bg h3 {
  margin-top: 10px;
}

.rich-text-content a {
  color: #89c4f4;
  text-decoration: none;
  font-size: 18px;
}

.rich-text-content a:hover {
  text-decoration: underline;
}

.about h2 {
  color: #f9b418;
  margin-top: 35px;
  font-size: 34px;
  font-weight: 700;
}

.about h3 {
  font-size: 36px;
  font-weight: 500;
}

.about h4,
.offering h2 {
  font-size: 24px;
  font-weight: 500;
}

.about p {
  margin: 20px 0;
}

.about .d-flex {
  display: flex;
  justify-content: center;
  gap: 8%;
  margin: 50px auto;
  width: 100%;
  box-shadow: 0px 0.9px 0.8px 2.4px #2a2a2a14;
  padding: 5px;
}

.about .profile_img {
  width: 20%;
  margin: auto;
}

.profile_img img {
  width: 100%;
}

.about .rich-text-content {
  width: 70%;
  text-align: justify;
}

.OurApproach .rich-text-content {
  width: 50%;
  text-align: justify;

}

.OurApproach {
  background-color: #f9b418;
  padding: 50px 0px;
}

.OurApproach h2 {
  text-align: center;
  color: #ffff;
  font-size: 54px;
  font-weight: 500;
  padding-bottom: 20px;
}

.OurApproach .d-flex {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.OurApproach .rich-text-content p {
  color: #ffff;
  font-size: 16px;
  padding: 0px 0px 15px 0px;
}

.approach_image {
  width: 50%;
}

.testimonials h2 {
  text-align: center;
  font-size: 54px;
  font-weight: 500;
  margin-bottom: 20px;
}

.testimonials .rich-text-content h3 {
  font-size: 36px;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 10px;
}

.testimonials {
  padding: 50px 0px;
}

.slick-prev:before,
.slick-next:before {
  color: #656565;
}

.Offerings {
  padding: 50px 0px;
}

.Offerings h2 {
  text-align: center;
  font-size: 54px;
  font-weight: 500;
  line-height: 1.2;
}

.Offerings .slide_bg {
  background-color: #fff;
  border: 2px solid #faed27;
  margin: 30px auto;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  overflow: hidden;
}

.Offerings .slide_bg img {
  max-width: 100%;
  /* Ensure the image fits within the circular box */
  height: auto;
  width: auto;
}

.Offerings .text_Wrap h2 {
  font-size: 24px;
  margin: 10px 0px 20px 0px;
}

.Offerings .text_Wrap {
  width: 100%;
  margin: 20px 0px;
}

.Offerings .slide_bg a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Offerings .text_Wrap .vc_sep_line {
  height: 1px;
  border-top: 5px solid #ebebeb;
  border-color: #f1c40f;
  display: block;
  width: 50%;
  margin: auto;
  padding-bottom: 20px;

}

p {
  color: #918a8a;
  font-size: 16px;
}

h1,
h2,
h3,
h4 {
  font-family: "Oxygen", sans-serif;
}

.home h2 {
  z-index: 5;
  
  white-space: normal;
  font-size: 54px;
  line-height: 65px;
  font-weight: 700;
  color: rgb(25, 25, 25);
  font-family: Oxygen;
  text-shadow: rgba(0, 0, 0, 0.1) 0px 1px 10px;
  visibility: inherit;
  transition: none 0s ease 0s;
  border-width: 0px;
  margin: 0px;
  padding: 0px;
  letter-spacing: 0px;
 
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform-origin: 50% 50% 0px;
}

.SubOfferingChild {
  margin-top: 50px;
}

.SubOfferingChild .reference {
  gap: 12%;
}

.SubOfferingChild .vc_sep_line {
  margin-bottom: 30px;
}

.offering .reference h2 {
  margin: 10px 0;
}

@media screen and (max-width: 768px),
(max-width: 360px) {
  .home h2{
    font-size: 40px;
    text-align: center;
  }
  .slide_Wrap,
  .slide_bg,
  .reference,
  .whats_new .d-flex,
  .about .d-flex,
  .OurApproach .d-flex {
    flex-direction: column;
    align-items: center;
  }

  body,
  .slide_bg img,
  .container,
  .text_Wrap,
  .whats_new_slide_bg img,
  .slide_bg {
    max-width: 100%;
    margin: auto;
  }

  .offering .slide_Wrap {
    gap: 50px;
  }

  .offering .vc_sep_line {
    padding: 10px 0;
  }

  .slide_bg {
    width: 100%;
  }

  .whats_new_slide_bg {
    width: 100%;
  }

  .reference .d-flex {
    text-align: center;
    margin: auto;
  }

  .reference .d-flex h2 {
    margin-top: 10px;
    text-align: left;
    font-size: 22px;
  }

  .reference .wrapper {
    width: 100%;
  }

  .whats_new .rich-text-content {
    margin: 20px;
    padding: 10px;
  }

  .offering .text_Wrap {
    width: 100%;
  }

  .offering .text_Wrap h2 {
    text-align: left;
  }

  .offering,
  .whats_new,
  .contact_us,
  .page_title {
    width: 96%;
  }

  .about .profile_img,
  .about .rich-text-content,
  .approach_image,
  .OurApproach .rich-text-content {
    width: 100%;
  }

  .testimonials.rich-text-content {
    text-align: justify;
  }

  .offering .wrapper:last-child .slide_Wrap {
    flex-direction: column;
  }

  .SubOfferingChild .reference>div {
    margin: 20px 0;
  }
}
