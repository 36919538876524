.footer {
  background-color: #f9b418;
}

.footer-container {
  max-width: 100%;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.footer_logo {
  width: 30%;
}

.footer_logo img {
  max-width: 100%;
  width: 250px;
  height: auto;
}

.explore {
  width: 45%;
  padding: 20px 10px;
}

.explore h4 {
  font-size: 24px;
  color: #ffffff;
  font-weight: 500;
}

#links {
  list-style: none;
  width: 50%;
}

#links li {
  padding: 10px 0;
  border-bottom: 1px solid #fff;
  position: relative;
  line-height: 15px;
  box-sizing: border-box;
  font-size: 30px;
}

#links li a {
  text-decoration: none;
  font-size: 12.8px;
  font-weight: 500;
  color: #000;
  transition: 0.3s ease-in-out;
  color: #fff;
  text-align: center;
  font-family: "Oxygen", sans-serif;
}

footer.container {
  max-width: 100%;
  width: 90%;
  margin: auto;
}

.copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.copyright h6 {
  font-size: 12.6px;
  font-family: "Lora", serif;
  color: #656565;
  font-weight: 400;
}

.handle {
  display: flex;
  gap: 8px;
}

.handle a {
  background: rgba(34, 49, 63, 0.5);
  border-radius: 5px;
  color: #ffffff;
  padding: 5.5px 10px;
}

@media only screen and (max-width: 768px) {
  .footer-container,
  .copyright {
    flex-direction: column;
    gap: 10px;
  }

  .footer-container {
    max-width: 100%;
  }

  .copyright h6 {
    text-align: center;
  }

  .footer_logo img {
    max-width: 100%;
    height: auto;
  }

  .handle a {
    text-align: center;
  }

  #links,
  .explore {
    width: 100%;
  }

  .testimonials .slick-prev {
    bottom: -33px !important;
    top: auto !important;
    left: 35% !important;
  }

  .testimonials .slick-next {
    bottom: -33px !important;
    top: auto !important;
    right: 45% !important;
  }
}
