.home {
    position: relative;
    z-index: 0;
}

.home .slideWrap {
    min-height: 100vh;
    width: 100%;
    display: flex;
    padding: 60px;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    transition: filter 3.5s;

    >* {
        opacity: 1;
        transform: translateY(55px);
        transition: all 0.4s;
    }

    @media(max-width: 760px) and (orientation: landscape) {
        min-height: 110vh;
        padding: 70px 20px;
        
    }

    @media(max-width: 760px) and (orientation: portrait) {
        min-height: 80vh;
        padding: 70px 20px;
        align-items: unset;
    }
}

.home .textWrap {
    max-width: 900px;
    text-align: center;

    h2 {
        font-size: 6em;
        line-height: 1.1;
        color: #fff;
        margin-bottom: 10px;
        text-shadow: 0 3px 5px rgba(255, 16, 16, 0.5);

        @media(max-width:991px) {
            font-size: 5em;
        }
    }
}
